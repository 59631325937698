import styled from 'styled-components'
import Container from '@/components/Container'
import SectionWrapper from '@/components/SectionWrapper'
import { Container as CollapseContainer } from '@/components/Collapse/Collapse.styles'
import { PrimaryTitleContainer } from '../../components/PrimaryTitle/PrimaryTitle.styles'

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.sectionTheme?.background};

  > ${Container} > ${PrimaryTitleContainer} + * {
    margin-top: 40px;
  }

  > ${Container} {
    max-width: 800px;
  }
`

export const NestedListWrapper = styled.div<{ $sectionTheme?: string }>`
  max-width: 800px;

  &,
  > ${CollapseContainer} + ${CollapseContainer} {
    border-top: 1px solid
      ${({ theme }) => theme.sectionTheme?.dividerColor};
  }

  svg path {
    fill: ${({ theme }) => theme.sectionTheme?.lightSubText};
  }
`
