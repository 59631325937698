import { useGetSectionTheme } from '@/hooks'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import {
  ContentItemInterface,
  ContentItemUITemplateKeys,
} from '@/interfaces/contentItem'
import {
  Wrapper, NestedListWrapper 
} from './FAQs.styles'
import { useState } from 'react'
import { PrimaryTitle } from '@/components/WrapperContentItems/components'
import ContentItem from '@/components/ContentItem'

const MIN = 3
const MAX = 15

const FAQs: React.FC<WrapperContentInterface> = ({
  theme,
  customTheme,
  primaryTitle,
  itemList,
}) => {
  const sectionTheme = useGetSectionTheme(theme, customTheme)
  const nestedItems = itemList.filter(
    (item: ContentItemInterface) =>
      item.uiTemplate === ContentItemUITemplateKeys.NestedItem
  )
  if (nestedItems.length < MIN || nestedItems.length > MAX) {
    return null
  }

  // First FAQ item is always open on mount
  const [openedIndex, setOpenedIndex] = useState(0)

  const handleItemChange = (isOpened: boolean, index: number) => {
    setOpenedIndex(isOpened ? index : -1)
  }

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <Wrapper>
        <PrimaryTitle>{primaryTitle}</PrimaryTitle>
        <NestedListWrapper $sectionTheme={theme}>
          {nestedItems.map((item: ContentItemInterface, index: number) => (
            <ContentItem
              key={index}
              data={item}
              wrapperTheme={theme}
              wrapperCustomTheme={customTheme}
              customProps={{
                index,
                isOpened: openedIndex === index,
                onChanged: handleItemChange,
              }}
            />
          ))}
        </NestedListWrapper>
      </Wrapper>
    </LocalThemeProvider>
  )
}

export default FAQs
